import { NgModule } from '@angular/core'
// import { ErrorHandler, Injectable, NgModule } from '@angular/core'
// import * as Sentry from '@sentry/browser'
import { BrowserModule } from '@angular/platform-browser'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { StoreModule } from '@ngrx/store'
import { LazyLoadImageModule } from 'ng-lazyload-image'
import { NgxMaskModule } from 'ngx-mask'
import { CommonModule } from '@angular/common'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ToastrModule } from 'ngx-toastr'
import { InfiniteScrollModule } from 'ngx-infinite-scroll'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { NotFoundComponent } from './pages/not-found/not-found.component'
import { LoginComponent } from './pages/login/login.component'
import { QueueComponent } from './pages/queue/queue.component'
import { ProfileComponent } from './pages/profile/profile.component'
import { DashboardComponent } from './pages/dashboard/dashboard.component'
import { AppointmentComponent } from './pages/appointment/appointment.component'
import { InputComponent } from './components/form/input/input.component'
import { SelectComponent } from './components/form/select/select.component'
import { CheckboxComponent } from './components/form/checkbox/checkbox.component'
import { RadiosComponent } from './components/form/radios/radios.component'
import { HeaderComponent } from './components/header/header.component'
import { SidebarComponent } from './components/sidebar/sidebar.component'
import { TableListComponent } from './components/table-list/table-list.component'
import { WorkbenchLayerComponent } from './components/workbench-layer/workbench-layer.component'
import { ComplaintComponent } from './components/exams/complaint/complaint.component'
import { BiometryComponent } from './components/exams/biometry/biometry.component'
import { StethoscopeComponent } from './components/exams/stethoscope/stethoscope.component'
import { ImagingExamsComponent } from './components/exams/imaging-exams/imaging-exams.component'
import { PatientInfoComponent } from './components/patient-info/patient-info.component'
import { ReportComponent } from './components/report/report.component'
import { VideoCallComponent } from './components/video-call/video-call.component'
import { stateReducers } from './state/reducers'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ImageComponent } from './components/image/image.component'
import { ButtonTooltipComponent } from './components/button-tooltip/button-tooltip.component'
import { TabComponent } from './components/tabs/tab/tab.component'
import { TabsComponent } from './components/tabs/tabs.component'
import { AccordionComponent } from './components/accordion/accordion.component'
import { CKEditorModule } from '@ckeditor/ckeditor5-angular'
import { BoxComponent } from './components/box/box.component'
import { AudioComponent } from './components/audio/audio.component'
import { ModalDefaultComponent } from './components/modals/modal-default/modal-default.component'
import { ModalFinalizedAppointmentComponent } from './components/modals/modal-finalized-appointment/modal-finalized-appointment.component'
import { SetValueDirective } from './directives/set-value.directive'
import { SetFormControlErrorDirective } from './directives/set-form-control-error.directive'
import { AuthInterceptorService } from './services/auth/auth-interceptor.service'
import { LoadingGlobalComponent } from './components/loading/loading-global/loading-global.component'
import { LoadingLocalComponent } from './components/loading/loading-local/loading-local.component'
import { ExamsComponent } from './components/exams/exams.component'
import { EcgComponent } from './components/exams/ecg/ecg.component'
import { LoadingProgressComponent } from './components/loading/loading-progress/loading-progress.component'
import { AlertComponent } from './components/alert/alert.component'
import { ModalMedicalCertificateComponent } from './components/modals/modal-medical-certificate/modal-medical-certificate.component'
import { ModalConfigurationComponent } from './components/modals/modal-configuration/modal-configuration.component'
import { SidebarItemComponent } from './components/sidebar/sidebar-item/sidebar-item.component'
// import { environment } from '../environments/environment'
import { VersionComponent } from './components/version/version.component'
import { PanelComponent } from './pages/panel/panel.component'
import { BodyDataComponent } from './components/exams/body-data/body-data.component'
import { NgChartsModule } from 'ng2-charts'
import { FaqComponent } from './pages/faq/faq.component'
import { StatusComponent } from './pages/status/status.component'
import { OnboardComponent } from './components/onboard/onboard.component'
import { StatusImageComponent } from './components/status-image/status-image.component'
import { ConfigComponent } from './pages/config/config.component'
import { TestingApisComponent } from './components/testing/testing-apis/testing-apis.component'
import { TestingInputVideoComponent } from './components/testing/testing-input-video/testing-input-video.component'
import { TestingOutputAudioComponent } from './components/testing/testing-output-audio/testing-output-audio.component'
import { TestingInputAudioComponent } from './components/testing/testing-input-audio/testing-input-audio.component'
import { FeedbackComponent } from './components/feedback/feedback.component'
import { CheckboxCustomComponent } from './components/form/checkbox-custom/checkbox-custom.component'
import { ScoreComponent } from './components/score/score.component'
import { ModalFeedbackComponent } from './components/modals/modal-feedback/modal-feedback.component'
import { NgSelect2Module } from 'ng-select2'
import { ModalDefaultInfoComponent } from './components/modals/modal-default-info/modal-default-info.component'
import { ImageCropperModule } from 'ngx-image-cropper'
import { PageOnboardComponent } from './pages/onboard/page-onboard.component'
import { SlickCarouselModule } from 'ngx-slick-carousel'
import { ResultExamsComponent } from './pages/result-exams/result-exams.component'
import { ModalActiveSectionComponent } from './components/modals/modal-active-section/modal-active-section.component'
import { QuizResponsesComponent } from './components/quiz-responses/quiz-responses.component'
import { ModalQuizComponent } from './components/modals/modal-quiz/modal-quiz.component'

// Sentry.init({
// 	dsn: 'https://bc11218c75db49199741bc5846633773@o246769.ingest.sentry.io/5881792',
// 	environment: environment.production ? 'prod' : 'dev',
// })
//
// @Injectable()
// export class SentryErrorHandler implements ErrorHandler {
// 	handleError(error: any) {
// 		Sentry.captureException(error.originalError || error)
// 	}
// }

@NgModule({
	declarations: [
		AppComponent,
		NotFoundComponent,
		LoginComponent,
		QueueComponent,
		ProfileComponent,
		DashboardComponent,
		AppointmentComponent,
		InputComponent,
		SelectComponent,
		CheckboxComponent,
		RadiosComponent,
		HeaderComponent,
		SidebarComponent,
		TableListComponent,
		WorkbenchLayerComponent,
		ComplaintComponent,
		BiometryComponent,
		StethoscopeComponent,
		ImagingExamsComponent,
		PatientInfoComponent,
		VideoCallComponent,
		ImageComponent,
		ButtonTooltipComponent,
		TabComponent,
		TabsComponent,
		AccordionComponent,
		BoxComponent,
		AudioComponent,
		SetValueDirective,
		SetFormControlErrorDirective,
		ModalDefaultComponent,
		ModalFinalizedAppointmentComponent,
		LoadingGlobalComponent,
		LoadingLocalComponent,
		ExamsComponent,
		EcgComponent,
		LoadingProgressComponent,
		AlertComponent,
		ModalMedicalCertificateComponent,
		ModalConfigurationComponent,
		ModalFeedbackComponent,
		ModalDefaultInfoComponent,
		ReportComponent,
		SidebarItemComponent,
		VersionComponent,
		PanelComponent,
		BodyDataComponent,
		FaqComponent,
		StatusComponent,
		OnboardComponent,
		StatusImageComponent,
		ConfigComponent,
		TestingInputAudioComponent,
		TestingOutputAudioComponent,
		TestingInputVideoComponent,
		TestingApisComponent,
		FeedbackComponent,
		CheckboxCustomComponent,
		ScoreComponent,
		PageOnboardComponent,
		ResultExamsComponent,
		ModalActiveSectionComponent,
		QuizResponsesComponent,
        ModalQuizComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		LazyLoadImageModule,
		StoreModule.forRoot({ ...stateReducers }, {}),
		NgbModule,
		FormsModule,
		ReactiveFormsModule,
		CKEditorModule,
		NgxMaskModule.forRoot(),
		CommonModule,
		BrowserAnimationsModule,
		ToastrModule.forRoot({
			positionClass: 'toast-top-right',
		}),
		NgChartsModule,
		NgSelect2Module,
		ImageCropperModule,
		SlickCarouselModule,
		InfiniteScrollModule,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptorService,
			multi: true,
		},
		// {
		// 	provide: ErrorHandler,
		// 	useClass: SentryErrorHandler,
		// },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
